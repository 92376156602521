import PropTypes from "prop-types";
import { GridItem, Grid } from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextOptions } from "@/components/modules/richTextOptions";
import { Section } from "@/components/layouts/Section";

export function RichTextGrid({
    sectionStyling,
    columns,
    reverseOrderOnMobile,
    centerVertically,
    columnGap,
    rowGap,
}) {

    const justifyContentMap = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      };

    return (
        <Section
            sx={{
                paddingY: 20,
                backgroundColor: "white",
                overflow: "hidden",
            }}
            sectionStyling={sectionStyling}
        >
            <Section.Content>
                <Grid
                    className="richTextGrid"
                    sx={{
                        alignItems: centerVertically ? "center" : "",
                        justifyItems: "center",
                        gridTemplateColumns: "repeat(12, 1fr)",
                        columnGap: {
                            base: columnGap.mobile || "16px",
                            md: columnGap.tablet || "16px",
                            xl: columnGap.desktop || "16px",
                        },
                        rowGap: {
                            base: rowGap.mobile || "16px",
                            md: rowGap.tablet || "16px",
                            xl: rowGap.desktop || "16px",
                        },
                    }}
                >
                    {columns.map((column, idx) => {
                        if (!column?.fields) return null;
                        
                        const justifyContent = justifyContentMap[column.fields.richTextAlignment] || 'flex-start';

                        return (
                            <GridItem
                                key={`${column.fields.identifierName}-${idx}`}
                                className="richTextGridColumn"
                                sx={{
                                    textAlign: column.fields.richTextAlignment || null,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: justifyContent,
                                    gridColumn: {
                                        base: `${column.fields.columnStartMobile || "auto"}/span ${column.fields.columnSizeMobile}`,
                                        md: `${column.fields.columnStartTablet || "auto"}/span ${column.fields.columnSizeTablet}`,
                                        xl: `${column.fields.columnStartDesktop || "auto"}/span ${column.fields.columnSizeDesktop}`,
                                    },
                                    gridRow: {
                                        base: reverseOrderOnMobile ? `${columns.length - idx}` : "auto",
                                        md: "auto"
                                    }
                                }}
                            >
                                {documentToReactComponents(
                                    column.fields.richTextContent,
                                    richTextOptions
                                )}
                            </GridItem>
                        );
                    })}
                </Grid>
            </Section.Content>
        </Section>
    );
}

RichTextGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    reverseOrderOnMobile: PropTypes.bool,
    centerVertically: PropTypes.bool,
    columnGap: PropTypes.object,
    rowGap: PropTypes.object,
};

RichTextGrid.defaultProps = {
    reverseOrderOnMobile: false,
    centerVertically: false,
};
