import PropTypes from "prop-types";
import { Box, Flex, Heading, Text, chakra } from "@chakra-ui/react";
import { GradientBackground } from "../../primitives/GradientBackground";
import { ProductCard } from "../ProductCard";
import { Section } from "../../layouts/Section";
import { CheckIcon } from "../../icons";

import "swiper/css";
import "swiper/css/pagination";
import { MobileHeroSlider } from "./MobileHeroSlider";
import { navigationHeight } from "@/components/layouts/Navigation/Navigation";
import { useEffect, useState } from "react";
import { useComponentDidMount } from "@/hooks";

// iPhone 13 has with an address bar a height of 664px, and without 745px
// Width stays constantly at 390px
const mediaQuery = "(max-height: 660px) and (max-width: 380px)";

export function Hero({
    headline,
    displayHeadlineOnSmallDevices,
    products,
    usps,
    backgroundVideo,
    sx,
    ...rest
}) {
    const componentDidMount = useComponentDidMount();

    // See if height is large enough to include Headline for mobile devices
    // useMediaQuery from Chakra did not update DOM, so we use a custom implementation
    const [ isSmallHeightMobile, setIsSmallHeightMobile ] = useState(
        typeof window !== "undefined"
            ? window.matchMedia(mediaQuery).matches
            : null
    );
    useEffect(() => {
        let media = null;
        const updateTarget = (e) => {
            setIsSmallHeightMobile(e.matches);
        };

        if (componentDidMount) {
            if (typeof window !== "undefined") {
                media = window
                    .matchMedia(mediaQuery)
                    .addEventListener("change", updateTarget);
            }
        }

        return () => {
            media?.removeEventListener("change", updateTarget);
        };
    }, [ componentDidMount ]);

    // Shared between mobile and desktop layout
    const ProductCards = products?.map((productItem) => {
        if (!productItem.fields) return null;

        return <ProductCard context="hero" {...productItem.fields} key={productItem.sys.id} />;
    });

    return (
        <Section
            className="hero"
            {...rest}
            sx={{
                position: "relative",
                paddingTop: 10,
                paddingBottom: 28,
                ...sx,
            }}
        >
            <Section.Root>
                <Box
                    className="navigationHeightOffset"
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: "hide",
                        marginTop: {
                            base: `calc(-1 * ${navigationHeight.base})`,
                            md: `calc(-1 * ${navigationHeight.md})`,
                        },
                    }}
                >
                    {backgroundVideo?.fields ? (
                        <chakra.video
                            src={`https://${backgroundVideo.fields.file.url}`}
                            autoPlay
                            muted
                            playsInline
                            loop
                            sx={{
                                position: "absolute",
                                objectFit: "cover",
                                width: "full",
                                height: "full",
                            }}
                        />
                    ) : (
                        <GradientBackground
                            sx={{
                                top: 0,
                                bottom: 0,
                                height: "auto",
                                width: "full",
                            }}
                        />
                    )}
                </Box>
            </Section.Root>
            {!!headline && !isSmallHeightMobile && (
                <Section.Content
                    sx={{
                        display: displayHeadlineOnSmallDevices
                            ? "block"
                            : { base: "none", lg: "block" },
                    }}
                >
                    <Heading
                        as="h1"
                        sx={{
                            color: "white",
                            textAlign: "center",
                            fontSize: { base: null, lg: "60px" },
                            marginBottom: { base: 10, lg: 20 },
                        }}
                    >
                        {headline}
                    </Heading>
                </Section.Content>
            )}
            {!!ProductCards && (
                <>
                    {/* Mobile - Render as slider - (Chraka.useBreakpointValue) leads to hydration issues */}
                    <Box sx={{ display: { base: "block", lg: "none" } }}>
                        <MobileHeroSlider products={ProductCards} />
                    </Box>

                    {/* Desktop - Render as list */}
                    <Box sx={{ display: { base: "none", lg: "block" } }}>
                        <Flex
                            sx={{
                                columnGap: 8,
                                rowGap: 16,
                                marginTop: 5,
                                justifyContent: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            {ProductCards}
                        </Flex>
                    </Box>
                </>
            )}
            {!!usps && (
                <Section.Content>
                    <Flex
                        sx={{
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: {
                                base: 12,
                                lg: 20,
                            },
                            columnGap: 24,
                            rowGap: 3,
                            flexWrap: "wrap",
                        }}
                    >
                        {usps.map((uspItem) => {
                            if (!uspItem.fields) return null;
                            return (
                                <Text
                                    as="h4"
                                    key={uspItem.sys.id}
                                    sx={{
                                        color: "yellow",
                                        fontWeight: "black",
                                        display: "flex",
                                        gap: 2,
                                        alignItems: "center",
                                        marginBottom: 0,
                                    }}
                                >
                                    <CheckIcon />
                                    {uspItem.fields.uspText}
                                </Text>
                            );
                        })}
                    </Flex>
                </Section.Content>
            )}
        </Section>
    );
}

Hero.propTypes = {
    sx: PropTypes.any,
    headline: PropTypes.string,
    displayHeadlineOnSmallDevices: PropTypes.bool,
    products: PropTypes.array.isRequired,
    usps: PropTypes.array,
    backgroundVideo: PropTypes.object,
};
