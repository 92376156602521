import { Faq } from "./Faq";
import { Hero } from "./Hero";
import { Quote } from "./Quote";
import { RowList } from "./RowList";
import { TextContent } from "./TextContent";
import { RichTextGrid } from "./RichTextGrid";
import { SnackSlider } from "./SnackSlider";
import { TikTokHero } from "./TikTokHero";
import { AnchorTag } from "../primitives/AnchorTag";
import { FlexibleSubscription } from "./FlexibleSubscription";

/**
 * Map components from Contentful Models.
 * Inspiration: https://strapi.io/blog/how-to-create-pages-on-the-fly-with-dynamic-zone
 *
 * !!! WARNING !!!
 * In this code write "module" in singular, and not plural since "modules" is a reserved Node.js word.
 */
export const getModuleComponent = (moduleData) => {
    const __component = moduleData.sys.contentType?.sys.id;
    let Module = {
        Component: null,
        props: null,
    };

    switch (__component) {
        case "frontpageHero":
            Module = {
                Component: Hero,
                props: {
                    headline: moduleData.fields.headline,
                    displayHeadlineOnSmallDevices:
                        moduleData.fields.displayHeadlineOnSmallDevices,
                    products: moduleData.fields.products,
                    usps: moduleData.fields.usp?.fields.uspItems,
                    backgroundVideo: moduleData.fields.backgroundVideo,
                },
            };
            break;
        case "quote":
            Module = {
                Component: Quote,
                props: {
                    quote: moduleData.fields.quote,
                    backgroundAsset:
                        moduleData.fields?.backgroundAsset?.fields.file,
                },
            };
            break;
        case "rowList":
            Module = {
                Component: RowList,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    headline: moduleData.fields.headline,
                    headlineColor: moduleData.fields.headlineColor,
                    includeSeparator: moduleData.fields.includeSeparator,
                    columns: moduleData.fields.columns,
                },
            };
            break;
        case "faq":
            Module = {
                Component: Faq,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    headline: moduleData.fields.headline,
                    headlineColor: moduleData.fields.headlineColor,
                    categories: moduleData.fields.categories,
                },
            };
            break;
        case "textContent":
            Module = {
                Component: TextContent,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    headline: moduleData.fields.headline,
                    headlineAlignment: moduleData.fields.headlineAlignment,
                    richTextContent: moduleData.fields.richTextContent,
                    richTextAlignment: moduleData.fields.richTextAlignment,
                    background: moduleData.fields.backgroundColor,
                    textColor: moduleData.fields.textColor,
                },
            };
            break;
        case "snackSlider":
            Module = {
                Component: SnackSlider,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    headline: moduleData.fields.headline,
                    slides: moduleData.fields.slides,
                },
            };
            break;
        case "richTextGrid":
            Module = {
                Component: RichTextGrid,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    columns: moduleData.fields.columns,
                    reverseOrderOnMobile:
                        moduleData.fields.reverseOrderOnMobile,
                    centerVertically: moduleData.fields.centerVertically,
                    columnGap: {
                        mobile: moduleData.fields.columnGapMobile,
                        tablet: moduleData.fields.columnGapTablet,
                        desktop: moduleData.fields.columnGapDesktop,
                    },
                    rowGap: {
                        mobile: moduleData.fields.rowGapMobile,
                        tablet: moduleData.fields.rowGapTablet,
                        desktop: moduleData.fields.rowGapDesktop,
                    },
                },
            };
            break;
        case "tikTokHero":
            Module = {
                Component: TikTokHero,
                props: {
                    sectionStyling: moduleData.fields.sectionStyling?.fields,
                    ttVideos: moduleData.fields.videoLinks,
                    headline: moduleData.fields.headline,
                    description: moduleData.fields.description,
                },
            };
            break;
        case "anchorTag":
            Module = {
                Component: AnchorTag,
                props: {
                    anchorId: moduleData.fields.anchorId,
                },
            };
            break;
        case "flexibleSubscription":
            Module = {
                Component: FlexibleSubscription,
                props: {
                    headline: moduleData.fields.header,
                    defaultCountry: moduleData.fields.defaultCountry,
                    defaultNetwork: moduleData.fields.defaultNetwork,
                    defaultData: moduleData.fields.defaultData,
                    options: {
                        eu: {
                            "4g": moduleData.fields.eu4g || [],
                            "5g": moduleData.fields.eu5g || [],
                        },
                        dk: {
                            "4g": moduleData.fields.dk4g || [],
                            "5g": moduleData.fields.dk5g || [],
                        },
                    },
                },
            };
            break;
    }

    return Module.Component ? (
        <Module.Component
            key={`index-${moduleData.sys.id}`}
            {...Module.props}
        />
    ) : null;
};
