import { Box, Button, Flex, Heading, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { DottedLine } from "./DottedLine";
import PropTypes from "prop-types";
import Image from "next/image";
import NextLink from "next/link";
import { motion } from "framer-motion";

export function RowListItem({
    headline,
    description,
    image,
    isLast,
    buttonText,
    buttonUrl,
    buttonNewWindow,
    includeSeparator,
}) {
    const isMobile = useBreakpointValue({
        base: true,
        lg: false,
    });

    const RowListImage = () => (
        <Image
            src={`https:${image.fields.file.url}`}
            width={image.fields.file.details.image.width}
            height={image.fields.file.details.image.height}
            alt={image.fields.description || ""}
        />
    );

    return (
        <Box
            as={buttonUrl ? motion.div : "div"}
            sx={{
                position: "relative",
                flexBasis: {
                    base: "100%",
                    lg: "33.3%",
                },
                textAlign: "center",
                _hover: {
                    ".chakra-button": {
                        boxShadow: "0px 40px 20px -20px rgb(0,0,0, 0.1)",
                    }
                }
            }}
            {...(buttonUrl && {
                whileHover: {
                    scale: isMobile ? null : 1.1,
                }
            })}
        >
            {image && (
                <Flex
                    sx={{
                        position: {
                            base: "static",
                            lg: "relative",
                        },
                        justifyContent: "center",
                        marginBottom: 10,
                    }}
                >
                    {buttonUrl ? (
                        <Link
                            as={NextLink}
                            href={buttonUrl}
                            variant="none"
                            target={buttonNewWindow ? "_blank" : undefined}
                        >
                            <RowListImage />
                        </Link>
                    ) : (
                        <RowListImage />
                    )}

                    {includeSeparator && !isLast && (
                        <DottedLine
                            sx={{
                                position: "absolute",
                                top: {
                                    base: "100%",
                                    lg: "50%",
                                },
                                left: {
                                    base: "50%",
                                    lg: "100%",
                                },
                                maxWidth: {
                                    base: 12,
                                    lg: "40%",
                                },
                                marginTop: {
                                    base: 10,
                                    lg: 0,
                                },
                                marginLeft: {
                                    lg: 5, // column gap diveded by 2
                                },
                                color: "gray.lighter",
                                transformOrigin: "center left",
                                transform: {
                                    base: "rotate(90deg)",
                                    lg: "translateX(-50%)",
                                },
                            }}
                        />
                    )}
                </Flex>
            )}

            {headline && (
                <Heading
                    as="h3"
                    variant="gradient"
                    textStyle="h2"
                    sx={{
                        marginBottom: [2, null, null, 4],
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {headline}
                </Heading>
            )}

            {description && (
                <Text
                    sx={{
                        fontWeight: "black",
                        fontSize: "h4",
                        color: "text",
                        margin: 0,
                        marginBottom: {
                            base: 6,
                            lg: 10,
                        },
                    }}
                >
                    {description}
                </Text>
            )}

            {buttonText && buttonUrl && (
                <Button
                    href={buttonUrl}
                    as={NextLink}
                    variant={"rounded"}
                    size="full"
                    target={buttonNewWindow ? "_blank" : undefined}
                >
                    {buttonText}
                </Button>
            )}
        </Box>
    );
}

RowListItem.propTypes = {
    headline: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonNewWindow: PropTypes.bool,
    includeSeparator: PropTypes.bool,
    isLast: PropTypes.bool,
};

RowListItem.defaultProps = {
    includeSeparator: false,
    isLast: false,
}
