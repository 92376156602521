import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

import circleImg from "./../../../../public/circle.png"

const fullWidthAndAbsolute = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
};


/**
 * @param { node } children Image or Video
 * @param { boolean } isBackground Set as absolute and fill wrapping container
 * @param { boolean } hasDots Apply dotted overlay
 *
 * Module that wraps your asset (image/video) inside an overlay of gradient.
 * Possibly with dotted style
 */
export function GradientAsset({
    children,
    hasDots,
    isBackground,
    sx,
}) {
    return (
        <Box
            sx={{
                ...(isBackground ? {
                    ...fullWidthAndAbsolute,
                    zIndex: -1,
                } : {
                    position: "relative",
                }),
                "> span": {
                    display: "block !important"
                },
                ...sx,
            }}
        >
            {/* Image / Video */}
            {children}

            {/* Gradient overlay */}
            <Box className="gradient-overlay" sx={{
                ...fullWidthAndAbsolute,
                opacity: 0.8,
                zIndex: 2,
                background: "gradients.main"
            }} />

            {/* Circular dots overlay */}
            {hasDots && (
                <Box className="circular-dots" sx={{
                    ...fullWidthAndAbsolute,
                    opacity: 0.6,
                    zIndex: 3,
                    background: `url(${circleImg.src}) repeat`
                }} />
            )}
        </Box>
    );
}

GradientAsset.propTypes = {
    children: PropTypes.node,
    hasDots: PropTypes.bool,
    isBackground: PropTypes.bool,
    sx: PropTypes.object,
}

GradientAsset.defaultProps = {
    isBackground: false,
    hasDots: false,
}
