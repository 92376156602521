import { chakra } from "@chakra-ui/react";

export function DottedLine(props) {
    return (
        <chakra.svg
            width={177}
            height={"6px"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={3} cy={3} r={3} fill="currentColor" />
            <circle cx={13} cy={3} r={3} fill="currentColor" />
            <circle cx={23} cy={3} r={3} fill="currentColor" />
            <circle cx={33} cy={3} r={3} fill="currentColor" />
            <circle cx={43} cy={3} r={3} fill="currentColor" />
            <circle cx={53} cy={3} r={3} fill="currentColor" />
            <circle cx={63} cy={3} r={3} fill="currentColor" />
            <circle cx={73} cy={3} r={3} fill="currentColor" />
            <circle cx={83} cy={3} r={3} fill="currentColor" />
            <circle cx={93} cy={3} r={3} fill="currentColor" />
            <circle cx={103} cy={3} r={3} fill="currentColor" />
            <circle cx={113} cy={3} r={3} fill="currentColor" />
            <circle cx={123} cy={3} r={3} fill="currentColor" />
            <circle cx={133} cy={3} r={3} fill="currentColor" />
            <circle cx={143} cy={3} r={3} fill="currentColor" />
            <circle cx={153} cy={3} r={3} fill="currentColor" />
            <circle cx={163} cy={3} r={3} fill="currentColor" />
            <circle cx={173} cy={3} r={3} fill="currentColor" />
        </chakra.svg>
    );
}
