import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Box, Button, Heading } from "@chakra-ui/react";
import { Section } from "../../layouts/Section";
import { richTextOptions } from "../richTextOptions";
import PropTypes from "prop-types";
import Nextlink from "next/link";
import { contentfulBackground, contentfulTextColor } from "@/theme/contentful-mappings";

/**
 * Text & Button purpose is currently only for Hardcoded content E.g. 404 page.
 */
export function TextContent({
    headline,
    headlineAlignment,
    richTextContent,
    richTextAlignment,
    children,
    button,
    background,
    textColor,
    sectionStyling,
}) {
    return (
        <Section
            sectionStyling={sectionStyling}
            sx={{
                paddingY: 10,
                textAlign: "center",
                ...contentfulTextColor(textColor),
                ...contentfulBackground(background),
            }}
        >
            <Section.Content maxW="container.lg">
                {!!headline && (
                    <Heading as="h2" textStyle="h1" sx={{ textAlign: headlineAlignment, marginBottom: 4, }}>{headline}</Heading>
                )}

                {!!richTextContent && (
                    <Box sx={{ textAlign: richTextAlignment }}>
                        {documentToReactComponents(richTextContent, richTextOptions)}
                    </Box>
                )}

                {children}

                {!!button && (
                    <Button as={Nextlink} href="/" variant="action" sx={{ marginTop: 4 }}>Gå til forsiden</Button>
                )}
            </Section.Content>
        </Section>
    );
}

TextContent.propTypes = {
    headline: PropTypes.string,
    headlineAlignment: PropTypes.string,
    richTextContent: PropTypes.object,
    richTextAlignment: PropTypes.string,
    children: PropTypes.node,
    button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    background: PropTypes.string,
    textColor: PropTypes.string,
}
