import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Heading, Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FaqCategory } from "./FaqCategory";
import { Section } from "@/components/layouts/Section";
import { contentfulTextColor } from "@/theme/contentful-mappings";

export function Faq({ headline, headlineColor, categories, sectionStyling }) {
    const [openQuestions, setOpenQuestions] = useState([]);
    const router = useRouter();
    const contentfulHeadlineColor = useMemo(
        () => contentfulTextColor(headlineColor),
        [headlineColor]
    );

    useEffect(() => {
        setOpenQuestions((openQuestions) => [
            ...openQuestions,
            router.asPath.split("#")[1],
        ]);
    }, [router]);

    // Toggle clicked questions
    const onQuestionClick = (questionId, questionText, questionCategory) => {
        if (openQuestions.includes(questionId)) {
            // Question is already open, close it
            setOpenQuestions([]); // empty the open questions array
        } else {
            // Open question
            setOpenQuestions([questionId]); // add the clicked question to the array
            // Push FAQ Click to dataLayer. Related Ticket PX-551
            window.dataLayer = window.dataLayer || []; // if dataLayer doesn't exist yet, initialize it
            window.dataLayer.push({
                event: "accordion_click",
                accordionCategory: questionCategory || "Undefined",
                accordionClick: questionText || "Undefined",
            });
        }
    };

    const hasMoreThanOneCategory = categories.length > 1;

    return (
        <Section
            sectionStyling={sectionStyling}
            sx={{
                paddingY: 20,
            }}
        >
            <Section.Content
                sx={{
                    maxWidth: hasMoreThanOneCategory
                        ? {
                              "2xl": "container.lg",
                          }
                        : {
                              xl: "container.md",
                              "2xl": "container.md",
                          },
                }}
            >
                {!!headline && (
                    <Heading
                        as="h2"
                        sx={{
                            textAlign: "center",
                            marginBottom: 8,
                            ...contentfulHeadlineColor,
                        }}
                    >
                        {headline}
                    </Heading>
                )}

                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            base: "1fr",
                            md: hasMoreThanOneCategory ? "1fr 1fr" : null,
                        },
                        columnGap: 8,
                        rowGap: 8,
                    }}
                >
                    {categories.map((categoryItem) => {
                        if (!categoryItem.fields) return null;
                        return (
                            <FaqCategory
                                key={categoryItem.sys.id}
                                icon={categoryItem.fields.icon}
                                headline={categoryItem.fields.headline}
                                questions={categoryItem.fields.questions}
                                onQuestionClick={onQuestionClick}
                                openQuestions={openQuestions}
                            />
                        );
                    })}
                </Box>
            </Section.Content>
        </Section>
    );
}

Faq.propTypes = {
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    categories: PropTypes.array.isRequired,
};
