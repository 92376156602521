import PropTypes from "prop-types";

export function AnchorTag({ anchorId }) {
    return (
        <div id={anchorId}></div>
    );
};

AnchorTag.propTypes = {
    anchorId: PropTypes.string.isRequired,
}
