import { useMemo } from "react";
import PropTypes from "prop-types";
import { Flex, Heading } from "@chakra-ui/react";
import { Section } from "@/components/layouts/Section";
import { RowListItem } from "./RowListItem";
import { contentfulTextColor } from "@/theme/contentful-mappings";

export function RowList({
    headline,
    headlineColor,
    columns,
    includeSeparator,
    sectionStyling,
}) {
    const contentfulHeadlineColor = useMemo(() => contentfulTextColor(headlineColor), [headlineColor]);

    return (
        <Section
            sectionStyling={sectionStyling}
            sx={{
                paddingY: "6rem",
                backgroundColor: "gray.lightest",
            }}
        >
            <Section.Content>
                {headline && (
                    <Heading
                        as="h2"
                        sx={{
                            ...contentfulHeadlineColor,
                            textAlign: "center",
                            marginBottom: 12,
                        }}
                    >
                        {headline}
                    </Heading>
                )}

                <Flex
                    sx={{
                        columnGap: 10,
                        rowGap: {
                            base: includeSeparator ? 32 : 10,
                            lg: 0,
                        },
                        flexWrap: {
                            base: "wrap",
                            lg: "nowrap",
                        },
                        justifyContent: "center",
                    }}
                >
                    {columns.map((columnItem, idx, arr) => {
                        if (!columnItem.fields) return null;
                        const {headline, description, image, button} = columnItem.fields
                        return (
                            <RowListItem
                                key={columnItem.sys.id}
                                includeSeparator={includeSeparator}
                                headline={headline}
                                description={description}
                                image={image}
                                buttonText={button?.fields?.linkLabel}
                                buttonUrl={button?.fields?.linkUrl}
                                buttonNewWindow={button?.fields?.openInNewWindow}
                                isLast={idx === arr.length - 1} // Used to control when to show last separator
                            />
                        );
                    })}
                </Flex>
            </Section.Content>
        </Section>
    );
}

RowList.propTypes = {
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    columns: PropTypes.array.isRequired,
    includeSeparator: PropTypes.bool,
};

RowList.defaultProps = {
    includeSeparator: false,
};
